.anchorDiv {
  position: relative;
}

.photo_header {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  flex-direction: column;
}

.backgroundphoto {
  width: 100%;
  position: relative;
  object-fit: cover;
  padding: none;
  display: block;
  z-index: -1;
}

.backgroundvideo {
  width: 100%;
  position: relative;
  padding: none;
  display: block;
  z-index: -1;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner_text_container {
  list-style: none;
  margin-top: 4rem;
  margin-left: 4rem;
}

.banner_text_title {
  font-family: 'Kanit', sans-serif;
  font-weight: 500;
  font-size: 4.5rem;
  line-height: 1;
  color: #ffffff;
  text-shadow: 3px 3px #000000;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.banner_text_subtitle {
  font-family: 'Kanit', sans-serif;
  font-weight: 400;
  font-size: 3rem;
  line-height: 1;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: 3px 2px #000000;
}

.banner_subtext {
  font-weight: 600;
  font-size: 3rem;
  color: #ffffff;
  line-height: 1.1;
  margin-bottom: 2rem;
  margin-right: 2.7rem;
  text-align: right;

  display: flex;
  flex-direction: column;
  align-items: right;
}

.flex_grow {
  flex-grow: 1;
}

.summer_special_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 4rem;
}
.summer_special_section_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: 'Kanit', sans-serif;
  font-size: 4rem;
  line-height: 5.5rem;
  font-weight: 500;
  text-shadow: 3px 2px #000000;
}
.percent_off_text {
  font-size: 5rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #f7e519;
}
.summer_special_section_button {
  background-color: #00a651;
  border: 3px solid #f7e519;
  border-radius: 20px;
  cursor: pointer;
  padding: 1rem 2rem;
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
}
.nav_link {
  text-decoration: none;
}
.summer_special_section_button:hover {
  background-color: #f7e519;
  color: #000000;
}

.next_day_service {
  max-height: 17rem;
  width: auto;
  height: auto;
  border: none;
  display: block;
  float: left;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 1300px) {
  .banner_text_title {
    font-size: 5rem;
  }
  .banner_text_subtitle {
    font-size: 3.5rem;
  }
  .next_day_service {
    max-height: 30rem;
  }
}
@media only screen and (max-width: 851px) {
  .banner_text_container {
    margin-top: 3rem;
    margin-left: 3rem;
  }
  .banner_text_title {
    font-size: 3.5rem;
    text-shadow: 3px 2px #000000;
  }
  .banner_text_subtitle {
    font-size: 2.5rem;
    text-shadow: 2px 1px #000000;
  }
  .summer_special_section {
    margin-top: 3rem;
  }
  .summer_special_section_text {
    font-size: 2rem;
    line-height: 3rem;
  }
  .percent_off_text {
    font-size: 3rem;
  }
  .summer_special_section_button {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
  }
  .next_day_service {
    max-height: 12rem;
  }
}
@media only screen and (max-width: 601px) {
  .banner_text_container {
    margin-top: 3rem;
    margin-left: 3rem;
  }
  .banner_text_title {
    font-size: 3rem;
    text-shadow: 2px 2px #000000;
  }
  .banner_text_subtitle {
    font-size: 2rem;
  }
  .summer_special_section {
    margin-top: 2rem;
  }
  .summer_special_section_text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .percent_off_text {
    font-size: 2rem;
  }
  .summer_special_section_button {
    font-size: 1.2rem;
    padding: 0.2rem 0.5rem;
    border: 2px solid #f7e519;
  }
  .next_day_service {
    max-height: 11rem;
    display: none;
  }
}
@media only screen and (max-width: 451px) {
  .banner_text_container {
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .banner_text_title {
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .banner_text_subtitle {
    font-size: 1.5rem;
  }
  .next_day_service {
    max-height: 6rem;
  }
}
