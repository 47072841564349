.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container_dates {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 50rem;
}

.container_times_1 {
  background-color: #d7e0e2;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.container_times {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 50rem;
}

@media only screen and (max-width: 500px) {
  .container_dates {
    width: 35rem;
  }
  .container_times {
    width: 35rem;
  }
}
