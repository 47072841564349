.ckbox_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
}
.ckbox_img {
  max-width: 3rem;
  width: 3rem;
  height: auto;
  border: none;
  display: block;
  cursor: pointer;
}
.ckbox_desc {
  font-size: 2.2rem;
  line-height: 2rem;
  font-weight: 800;
  margin-left: 1.5rem;
  flex-grow: 1;
  border-bottom: 1px dashed #73939b;
}

.ckbox_price {
  font-size: 2.2rem;
  line-height: 2rem;
  font-weight: 800;
  text-align: right;
  padding-left: 1.5rem;
  padding-right: 2rem;
}

@media only screen and (max-width: 801px) {
}

@media only screen and (max-width: 500px) {
  .ckbox_desc {
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin-left: 1rem;
  }
  .ckbox_price {
    font-size: 2rem;
    padding-left: 1rem;
  }
}
