.container {
  color: #232e31;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 5rem;
  justify-content: space-between;

  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 451px) {
  .container {
    grid-gap: 1rem;
  }
}
