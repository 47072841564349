.quote_container {
  background-color: #00a651;
  height: 100vh;
}
.header_container {
  background-color: #232e31;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo_container {
  background-color: #ffffff;
}
.logo_top {
  background-color: #ffffff;
  max-height: 15rem;
  width: auto;
  height: auto;
  border: none;
  margin: auto;
  padding: 2rem 4rem 2rem 2rem;
  display: block;
}

.header_title {
  flex-grow: 1;
  font-family: 'Kanit', sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: right;
  color: #ffffff;
  padding-right: 3rem;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.form_container {
  background-color: #ffffff;
}

.div_spacer {
  background-color: #00a651;
  height: 2rem;
}
.div_grow {
  flex-grow: 1;
}

.rights_reserved {
  background-color: #232e31;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  padding-left: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.hideDIV {
  display: none;
}

/* START MODAL */
.modal_main {
  padding: 2rem;
}

.modal_header {
  display: flex;
  flex-direction: row;
}

.modal_content {
  font-size: 2rem;
  font-weight: 800;
  padding: 1rem 1rem 0.5rem 1rem;
  text-align: center;
}

.modal_button {
  font: inherit;
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
  background-color: #63828a;
  border: 2px solid #63828a;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  margin-left: 1rem;
  position: relative;
  right: -10px;
  top: -10px;
}

.modal_button:hover,
.modal_button:active {
  background-color: #00a651;
  border-color: #00a651;
  color: #e2d600;
}
/* END MODAL */

@media only screen and (max-width: 851px) {
  .header_title {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 451px) {
  .header_container {
    flex-direction: column;
  }
  .header_title {
    font-size: 2.2rem;
  }
  .logo_top {
    text-align: center;
  }
  .logo_container {
    width: 100%;
  }
}
