.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all, 0.3s;
}

.div_grow {
  flex-grow: 1;
}

.bug {
  max-height: 8rem;
  width: auto;
  height: auto;
  border: none;
  display: block;
  cursor: pointer;
}

.bug_title {
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  margin-top: 0.1rem;
}

@media only screen and (max-width: 851px) {
}
@media only screen and (max-width: 601px) {
}
@media only screen and (max-width: 451px) {
}
