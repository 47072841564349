.page_container {
  display: grid;
  grid-template-rows: min-content, 7;
  grid-template-columns: 100%;
  align-items: center;
}

.logo_header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.div_grow {
  flex-grow: 1;
}

.logo_top {
  max-height: 12rem;
  width: auto;
  height: auto;
  border: none;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  display: block;
}

.logo_bottom {
  background-color: #d7e0e2;
  max-width: 30rem;
  width: 100%;
  height: auto;
  border: none;
  padding: 1.5rem;
  display: inline-block;
  margin-bottom: 3rem;
}

.div_question_background {
  background-color: #b9c9cd;
}

.div_callback_background {
  background-color: #73939b;
}

.div_bottomlogo_background {
  background-color: #d7e0e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.div_33_percent {
  width: 33%;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
}

.button_container {
  background-color: #232e31;
  color: #ffffff;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.button_container:hover {
  background-color: #e2d600;
  color: #000000;
  cursor: pointer;
}

.button_free {
  background-color: #00a651;
  font-family: 'Kanit', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}
.button_content {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.button_nav {
  text-decoration: none;
}

@media only screen and (max-width: 851px) {
  .logo_top {
    max-height: 10rem;
    margin-left: 1.2rem;
  }
  .button_free {
    font-size: 2rem;
    padding: 0.5rem 1.3rem 0.5rem 1.3rem;
  }
  .button_content {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media only screen and (max-width: 601px) {
  .logo_top {
    max-height: 8rem;
    margin-top: 0.5rem;
    margin-left: 0.7rem;
    margin-bottom: 0.7rem;
  }
  .button_container {
    margin-right: 1rem;
  }
  .button_free {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.4rem 1rem 0.4rem 1rem;
  }
  .button_content {
    font-size: 1.1rem;
    font-weight: 600;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
  .footer {
    flex-direction: column;
  }
  .div_33_percent {
    width: 100%;
  }
}

.rights_reserved {
  font-size: 1.5rem;
  font-weight: 700;
  padding-left: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media only screen and (max-width: 451px) {
  .logo_top {
    max-height: 6rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.7rem;
  }
  .button_container {
    margin-right: 0.5rem;
  }
  .button_free {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  }
  .button_content {
    font-size: 1.3rem;
    font-weight: 600;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .button_container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .logo_bottom {
    width: 20rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 321px) {
  .button_container {
    margin-right: 0.3rem;
  }
  .button_free {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
  }
  .button_content {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .button_container {
    margin-left: 0.1rem;
    margin-right: 0.2rem;
  }
}
