.container {
  display: flex;
  flex-direction: row;
}

.houseimage {
  max-width: 60rem;
  width: 60rem;
  height: auto;
  border: none;
  display: block;
  position: absolute;
  z-index: 2;
}

.houseimageback {
  max-width: 60rem;
  width: auto;
  height: auto;
  border: none;
  display: block;
  z-index: 1;
}

.houseimage_overlay {
  max-width: 60rem;
  width: auto;
  height: auto;
  border: none;
  position: absolute;
  z-index: 3;
}

.img_container {
  background-color: #d7e0e2;
}

.desc_container {
  background-color: #d7e0e2;
  padding: 2rem;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.container_textarea {
  font-size: 2.5rem;
  font-weight: 800;

  display: flex;
  flex-direction: column;
}

.othertextarea {
  border: 8px solid #f7e519;
  border-radius: 8px;
  width: 100%;
  background-color: #fcf5c6;
  height: 12rem;
  padding: 1rem;
  font-family: 'Nunito', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  box-sizing: border-box;
  outline: none;
  resize: none;
}

.img_off {
  display: none;
}

.img_on {
  display: block;
}

@media only screen and (min-width: 1300px) {
  .houseimage {
    max-width: 800px;
    width: 800px;
  }

  .houseimageback {
    max-width: 800px;
    width: 800px;
  }

  .houseimage_overlay {
    max-width: 800px;
    width: 800px;
  }
}

@media only screen and (max-width: 1025px) {
  .container_textarea {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 801px) {
  .container {
    flex-direction: column;
  }

  .houseimage {
    max-width: 100%;
    width: 100%;
  }

  .houseimageback {
    max-width: 100%;
    width: 100%;
  }

  .houseimage_overlay {
    max-width: 100%;
    width: 100%;
  }

  .container_textarea {
    font-size: 2rem;
  }
}