.container {
  background-color: #232e31;
  color: #ffffff;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 4rem;
  justify-content: space-between;
}

@media only screen and (max-width: 851px) {
  .container {
    grid-gap: 2rem;
  }
}
@media only screen and (max-width: 601px) {
  .container {
    grid-gap: 1rem;
  }
}
@media only screen and (max-width: 451px) {
  .container {
    grid-gap: 0.1rem;
  }
}
