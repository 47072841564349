.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.eco_house {
  width: 45%;
  margin-right: 3rem;
}

.form_container {
  width: 55%;
}

.eco_house_img {
  max-width: 50rem;
  width: auto;
  height: auto;
  border: none;
  display: block;
}

.house_img1 {
  width: 100%;
  height: auto;
  border: none;
  display: block;
}
.house_img2 {
  width: 100%;
  height: auto;
  border: none;
  display: none;
}

/* BEGIN FORM */
.form {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  justify-content: center;
}

.form_group {
  width: 35rem;
}
.form_group:not(:last-child) {
  margin-bottom: 0.7rem;
}
.form_group:last-child {
  margin-bottom: 0.1rem;
}

.form_input {
  font-family: inherit; /* inherits the font family we are using in the page */
  font-size: 1.8rem;
  font-weight: 700;
  color: inherit;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border-bottom: 5px solid transparent;
  width: 100%;
  display: block;
  transition: all 0.5s; /* for the underline when you click in an input box */
}

.form_input:focus {
  outline: none;
  /*box-shadow: 2px 2px 3px #000000;*/
  border-bottom: 5px solid #00a651;
}

.form_label {
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 1.2rem;
  margin-bottom: 0.2rem;
  display: block;
  transition: all 0.5s; /* for the slide down animation of the labels */
}
.form_input:placeholder-shown + .form_label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.honeypot {
  display: none;
}

.invalid {
  border: 2px solid rgb(180, 14, 14);
  background-color: #fddddd;
}
.valid {
  background-color: #e0e7e9;
  border: 2px solid #232e31;
}

.button_container {
  text-align: center;
  margin-bottom: 2rem;
}

button {
  font: inherit;
  font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
  background-color: #00a651;
  color: white;
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 24px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #e2d600;
  color: #232e31;
  border-color: #232e31;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #9bb2b8;
  color: #63828a;
  border-color: #9bb2b8;
  cursor: not-allowed;
}
/* END FORM */

@media only screen and (max-width: 801px) {
}

@media only screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .eco_house {
    width: 100%;
    margin-right: 0;
  }

  .form_container {
    width: 100%;
  }

  .house_img1 {
    display: none;
  }
  .house_img2 {
    display: block;
  }
}
