.radio_container {
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.radio_container:hover {
  color: #00a651;
}

.radio_desc {
  font-family: Nunito, sans-serif;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 800;
  margin-left: 1rem;
}
.radio_img_button {
  max-width: 30px;
  width: 30px;
  height: auto;
  border: none;
  display: block;
}

@media only screen and (max-width: 500px) {
  .radio_img_button {
    max-width: 22px;
    width: 22px;
  }
  .radio_desc {
    font-size: 1.7rem;
  }
}
