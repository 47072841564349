.container {
  display: flex;
  flex-direction: row;
}

.container_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 67%;
}

.container_img {
  width: 33%;
  background-image: 'url(';
}

.container_bullet {
  margin-top: 2rem;
  width: 90%;
  font-family: Nunito, sans-serif;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 800;
}

.container_ckboxes {
  padding: 1rem 0 2rem 0;
  width: 90%;
  background-color: #d7e0e2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  display: flex;
  flex-direction: column;
}

.container_ckbox {
  margin-top: 0.7rem;
  width: 100%;
}

.container_content {
  width: 90%;
  padding-left: 2rem;
  font-size: 2rem;
  line-height: 2.5rem;

  display: flex;
  flex-direction: row;
}

.quoteblock {
  background-color: #f7e519;
  color: #000000;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem 1rem 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  border-radius: 10px;
}

.img_doorstep {
  width: 100%;
  height: auto;
  border: none;
  display: block;
}
.img_doorstep2 {
  width: 100%;
  height: auto;
  border: none;
  display: none;
}

.section_title {
  background-color: #00a651;
  color: #ffffff;
  font-size: 2.5rem;
  line-height: 2.2rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1.5rem 1rem 1.5rem 2rem;
  width: 90%;
}
.section_title_subheader {
  font-size: 1.8rem;
  text-transform: none;
}

.section_title_orange {
  background-color: #fca10d;
  color: #ffffff;
  font-size: 2.5rem;
  line-height: 2.2rem;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1.5rem 1rem 1.5rem 2rem;
  width: 90%;
}

.arrow_symbol {
  font-size: 18px;
  margin-top: 7px;
  margin-right: 10px;
}
.text_pop_1 {
  color: #e0190b;
  font-weight: 900;
  font-size: 2.5rem;
  text-decoration: line-through;
}
.text_pop_2 {
  color: #00a651;
  font-weight: 900;
  font-size: 3rem;
}
.text_pop_3 {
  color: #e0190b;
  font-size: 2.2rem;
  font-weight: 900;
}
.text_pop_4 {
  font-size: 2.7rem;
  font-weight: 900;
}

@media only screen and (min-width: 1300px) {
}

@media only screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .img_doorstep {
    display: none;
  }
  .img_doorstep2 {
    display: block;
  }
  .section_title {
    width: 95%;
  }
  .section_title_orange {
    width: 95%;
  }
  .container_bullet {
    width: 100%;
  }
  .container_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }
  .container_content {
    width: 95%;
    padding-left: 4rem;
    font-size: 1.7rem;
    line-height: 2rem;
  }
  .container_img {
    width: 100%;
  }
  .container_ckboxes {
    padding: 1rem 0 2rem 0;
    width: 95%;
  }
  .arrow_symbol {
    margin-top: 1px;
  }
  .text_pop_1 {
    font-size: 2.2rem;
  }
  .text_pop_2 {
    font-size: 2.2rem;
  }
  .text_pop_4 {
    font-size: 2.2rem;
  }
}
