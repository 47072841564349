.container {
  display: flex;
  flex-direction: row;
}

.houseimage {
  max-width: 60rem;
  width: auto;
  height: auto;
  border: none;
  display: block;
}

.desc_container {
  background-color: #d7e0e2;
  padding: 2rem;
  flex-grow: 1;
}
.house_list {
  list-style: none;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.house_list__li {
  display: flex;
  flex-direction: column;
}
.house_list__li_div1 {
  margin-top: 0.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}
.house_num_img {
  width: 3.3rem;
  height: auto;
  border: none;
}

h2 {
  font-size: 1.8rem;
  font-weight: 800;
  margin-left: 1.5rem;
}

.house_list__li_div2 {
  font-size: 1.5rem;
  line-height: 1.6rem;
  margin-left: 7.5rem;
}

@media only screen and (min-width: 1300px) {
  .houseimage {
    max-width: 80rem;
  }
  .house_num_img {
    width: 4.5rem;
  }
  h2 {
    font-size: 2.5rem;
    font-weight: 800;
    margin-left: 1.5rem;
  }
  .house_list__li_div2 {
    font-size: 2rem;
    line-height: 2rem;
    margin-left: 7.5rem;
  }
}
@media only screen and (max-width: 1000px) {
  .houseimage {
    max-width: 42rem;
  }
  .house_num_img {
    width: 2.5rem;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 800;
    margin-left: 1rem;
  }
  .house_list__li_div2 {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-left: 5rem;
  }
}
@media only screen and (max-width: 750px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .houseimage {
    max-width: 100%;
  }
  .house_num_img {
    width: 3rem;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 800;
    margin-left: 1.5rem;
  }
  .house_list__li_div2 {
    font-size: 1.5rem;
    line-height: 1.6rem;
    margin-left: 7.5rem;
  }
}
@media only screen and (max-width: 451px) {
  h2 {
    font-size: 1.5rem;
  }
  .house_list__li_div2 {
    margin-left: 6rem;
  }
}
@media only screen and (max-width: 350px) {
  h2 {
    font-size: 1.3rem;
  }
  .house_list__li_div2 {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
