.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all, 0.3s;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.container:hover {
  color: yellow;
  cursor: pointer;
  transform: scale(1.2, 1.2);
}

.div_grow {
  flex-grow: 1;
}

.bug {
  max-height: 8rem;
  width: auto;
  height: auto;
  border: none;
  display: block;
  cursor: pointer;
}

.bug_title {
  font-size: 1.8rem;
  line-height: 2rem;
  text-align: center;
  margin-top: 0.5rem;
}

.modal_main {
  padding: 2rem;
}

.modal_header {
  display: flex;
  flex-direction: row;
}

.modal_bug {
  max-height: 11rem;
  width: auto;
  height: auto;
  border: none;
  display: block;
  padding: 0.7rem;
}
.modal_bug_div {
  width: 11rem;
  height: 11rem;
}

.modal_bug_title {
  font-size: 4rem;
  font-weight: 800;
  color: #00a651;
  margin-top: 2rem;
  margin-left: 2rem;
}

.modal_content {
  font-size: 2rem;
  padding: 1rem 1rem 0.5rem 1rem;
}

.modal_footer {
  font-size: 2rem;
  background-color: #00a651;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0 0 6px 6px;
  padding: 1.5rem;
}

.modal_button {
  font: inherit;
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
  background-color: #63828a;
  border: 2px solid #63828a;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  margin-left: 1rem;
  position: relative;
  right: -10px;
  top: -10px;
}

.modal_button:hover,
.modal_button:active {
  background-color: #00a651;
  border-color: #00a651;
  color: #e2d600;
}

.button_container {
  background-color: #232e31;
  color: #ffffff;
  width: 40rem;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.button_container:hover {
  background-color: #e2d600;
  color: #000000;
  cursor: pointer;
}

.button_free {
  background-color: #ffffff;
  font-family: 'Kanit', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: #00a651;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}
.button_content {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.button_nav {
  text-decoration: none;
}

@media only screen and (max-width: 851px) {
  .bug {
    max-height: 7rem;
  }
  .bug_title {
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 601px) {
  .bug {
    max-height: 6rem;
  }
  .bug_title {
    font-size: 1.6rem;
  }
  .modal_bug {
    max-height: 10rem;
    padding: 0.6rem;
  }
  .modal_bug_div {
    border-radius: 13px;
    width: 10rem;
    height: 10rem;
  }
  .modal_bug_title {
    font-size: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .modal_content {
    font-size: 1.7rem;
    padding: 0.7rem 0.7rem 0 0.7rem;
  }
  .modal_footer {
    font-size: 1.7rem;
    font-weight: 800;
  }
}
@media only screen and (max-width: 451px) {
  .bug {
    max-height: 5rem;
  }
  .bug_title {
    font-size: 1.5rem;
  }
  .modal_bug {
    max-height: 8rem;
    padding: 0.5rem;
  }
  .modal_bug_div {
    border-radius: 10px;
    width: 8rem;
    height: 8rem;
  }
  .modal_bug_title {
    font-size: 3rem;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .modal_content {
    font-size: 1.5rem;
    padding: 0.7rem 0.7rem 0 0.7rem;
  }
  .modal_footer {
    font-size: 1.5rem;
    font-weight: 800;
  }
  .button_container {
    width: 30rem;
  }
  .button_free {
    font-size: 2rem;
  }
  .button_content {
    font-size: 1.5rem;
  }
}
