/* DATE PICKER */
.react-datepicker-wrapper {
  margin: 0 0 0 0 !important;
}
.react-datepicker-wrapper input {
  background-color: #e0e7e9 !important;
  font-family: inherit !important; /* inherits the font family we are using in the page */
  font-size: 1.5rem !important;
  text-align: left !important;
  color: inherit !important;
  padding: 0.5rem 1rem !important;
  border-style: none !important;
  border-radius: 2px !important;
  border-bottom: 3px solid transparent !important;
  width: 100% !important;
  display: block !important;
}

.react-datepicker__month-container {
  background-color: #e0e7e9 !important;
}

.react-datepicker {
  font-size: 1.5rem !important;
}

.react-datepicker__day--selected {
  background-color: #00a651 !important;
}

.react-datepicker__day--today {
  color: #ffffff !important;
  border: 2px solid
    linear-gradient(
      174deg,
      rgba(180, 14, 14, 0.736) 32%,
      rgba(216, 116, 116, 1) 100%
    );
  border-radius: 0.3rem;
  background: linear-gradient(
    174deg,
    rgba(180, 14, 14, 0.7357317927170868) 32%,
    rgb(216, 116, 116) 100%
  );
}

.react-datepicker__current-month {
  font-size: 1.8rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.6rem !important;
}

/*
.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1rem;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 9rem !important;
}
*/
/* END - DATE PICKER */
