.ckbox_container {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ckbox_img {
  max-width: 5rem;
  width: 3.5rem;
  height: auto;
  border: none;
  display: block;
  cursor: pointer;
}
.ckbox_desc {
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 800;
  margin-left: 1.5rem;
}

@media only screen and (max-width: 1025px) {
  .ckbox_desc {
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 801px) {
  .ckbox_desc {
    font-size: 2rem;
  }
}
