.container {
  background-color: #73939b;
  padding-top: 1.5rem;

  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
}

.div_grow {
  flex-grow: 1;
}

/* BEGIN FORM */
.form {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  justify-content: center;
}

.form_group {
  width: 80%;
}
.form_group:not(:last-child) {
  margin-bottom: 0.7rem;
}
.form_group:last-child {
  margin-bottom: 0.3rem;
}

.form_input {
  font-family: inherit; /* inherits the font family we are using in the page */
  font-size: 1.5rem;
  color: inherit;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  border-bottom: 3px solid transparent;
  width: 100%;
  display: block;
  transition: all 0.5s; /* for the underline when you click in an input box */
}

.form_input:focus {
  outline: none;
  /*box-shadow: 2px 2px 3px #000000;*/
  border-bottom: 3px solid #000000;
}

.form_label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 1.2rem;
  margin-top: 0.2rem;
  display: block;
  transition: all 0.5s; /* for the slide down animation of the labels */
}
.form_input:placeholder-shown + .form_label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.honeypot {
  display: none;
}

.invalid {
  border: 1px solid rgb(180, 14, 14);
  background-color: #fddddd;
}
.valid {
  background-color: #e0e7e9;
  border: none;
}

.button_container {
  text-align: center;
  margin-bottom: 2rem;
}

button {
  font: inherit;
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  background-color: #00a651;
  color: white;
  border: 1px solid #232e31;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #e2d600;
  color: #232e31;
  border-color: #232e31;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #9bb2b8;
  color: #63828a;
  border-color: #9bb2b8;
  cursor: not-allowed;
}
/* END FORM */

/* START MODAL */
.modal_main {
  padding: 2rem;
}

.modal_header {
  display: flex;
  flex-direction: row;
}

.modal_content {
  font-size: 2rem;
  font-weight: 800;
  padding: 1rem 1rem 0.5rem 1rem;
  text-align: center;
}

.modal_button {
  font: inherit;
  font-size: 2rem;
  font-weight: 900;
  cursor: pointer;
  background-color: #63828a;
  border: 2px solid #63828a;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  margin-left: 1rem;
  position: relative;
  right: -10px;
  top: -10px;
}

.modal_button:hover,
.modal_button:active {
  background-color: #00a651;
  border-color: #00a651;
  color: #e2d600;
}
/* END MODAL */
