.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}
.content {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
}

.button_container {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

button {
  font: inherit;
  font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
  background-color: #00a651;
  color: white;
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 24px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #e2d600;
  color: #232e31;
  border-color: #232e31;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #9bb2b8;
  color: #63828a;
  border-color: #9bb2b8;
  cursor: not-allowed;
}

@media only screen and (max-width: 500px) {
  .title {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 2rem;
  }
  .content {
    font-size: 1.7rem;
    font-weight: 500;
    margin-top: 1rem;
  }
  button {
    font-size: 2rem;
  }
}
