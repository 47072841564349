.nav_container {
  background-color: #00a651;
}
.top_nav {
  list-style: none;

  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-template-rows: 100%;
  grid-gap: 3rem;
  justify-content: space-evenly;
  z-index: 800;
  font-size: 1.8rem;
  font-weight: 500;
}

.top_nav a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: block;
  transition: all, 0.4s;
}

.top_nav a:hover,
.top_nav a:active,
.top_nav a.active {
  color: #000000;
  background-color: #e2d600;
  cursor: pointer;
}

@media only screen and (max-width: 601px) {
  .top_nav {
    grid-gap: 0.1rem;
    font-size: 1.5rem;
  }
  .top_nav a {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
}

@media only screen and (max-width: 451px) {
  .top_nav {
    grid-gap: 0.1rem;
    font-size: 1.2rem;
  }
  .top_nav a {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
}
