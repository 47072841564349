.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  position: fixed;
  top: 15vh;
  left: 10%;
  width: 80%;
  background-color: #e0e7e9;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 400ms ease-out forwards;
}

.content {
  font-size: 2rem;
}

@media (max-width: 768px) {
  .modal {
    height: 55vh;
    overflow-y: scroll;
  }
}

@media (max-width: 950px) {
  .modal {
    width: 95%;
    left: 2.5%;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-8rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
