.container {
  margin-top: 5rem;
  margin-bottom: 3rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.img_housesqft {
  max-width: 45rem;
  width: 45rem;
  height: auto;
  border: none;
  display: block;
  margin-right: 5rem;
}

.container_radio {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 800px) {
  .img_housesqft {
    max-width: 35rem;
    width: 35rem;
  }
}
@media only screen and (max-width: 700px) {
  .container {
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .img_housesqft {
    max-width: 20rem;
    width: 20rem;
    margin-right: 0;
    margin-left: 3rem;
    margin-bottom: 2rem;
  }
}
