.section_question {
  background-color: #f7e519;
  font-family: 'Kanit', sans-serif;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
  color: #000000;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}
.section_title {
  background-color: #b9c9cd;
  font-family: 'Kanit', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}
.section_nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section_nav_right {
  margin-top: 3rem;
  margin-right: 2rem;
  margin-bottom: 3rem;
}
.section_nav_left {
  margin-top: 3rem;
  margin-left: 2rem;
  margin-bottom: 3rem;
}

.section_nav_button {
  max-height: 45px;
  height: 45px;
}
.section_nav_button:hover {
  cursor: pointer;
}

.section_nav_button_disabled {
  max-height: 45px;
  height: 45px;
  opacity: 0.5;
}

.div_grow {
  flex-grow: 1;
}

@media only screen and (max-width: 451px) {
  .section_question {
    font-size: 2rem;
  }
  .section_title {
    font-size: 1.5rem;
  }
  .section_nav_button {
    height: 38px;
  }
}
