.container {
  font-family: 'Kanit', sans-serif;
  background-color: #00a651;
  height: 20rem;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.line_1 {
  font-size: 6rem;
  font-weight: 800;
  line-height: 0.5;
  color: #ffffff;
  text-align: center;
}

.line_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_container {
  background-color: #232e31;
  color: #ffffff;
  width: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.button_container:hover {
  background-color: #e2d600;
  color: #000000;
  cursor: pointer;
}

.button_free {
  background-color: #ffffff;
  font-family: 'Kanit', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: #00a651;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}
.button_content {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.button_nav {
  text-decoration: none;
}

@media only screen and (max-width: 851px) {
  .line_1 {
    font-size: 4.5rem;
  }
}
@media only screen and (max-width: 601px) {
  .container {
    height: 15rem;
  }
  .line_1 {
    font-size: 3.5rem;
  }
  .button_container {
    margin-right: 1rem;
  }
  .button_free {
    font-size: 2rem;
    font-weight: 500;
    padding: 0.4rem 1rem 0.4rem 1rem;
  }
  .button_content {
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}
@media only screen and (max-width: 451px) {
  .container {
    height: 10rem;
  }
  .line_1 {
    font-size: 2.5rem;
  }
  .button_container {
    margin-right: 1rem;
  }
  .button_free {
    font-size: 1.5rem;
    padding: 0.2rem 0.7rem 0.2rem 0.7rem;
  }
  .button_content {
    font-size: 1.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media only screen and (max-width: 350px) {
  .line_1 {
    font-size: 1.7rem;
  }
  .button_container {
    margin-right: 0.5rem;
  }
}
